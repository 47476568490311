// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/App.css */

/* Ensure the body and root take up the full height */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
}

/* Main container that centers everything */
.app-container {
  height: 100%;
  display: flex;
  justify-content: center;  /* Center horizontally */
  align-items: center;      /* Center vertically */
}

/* Flex container for the logo and button */
.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;  /* Center the content horizontally */
  gap: 20px;            /* Space between logo and button */
}

/* Logo styling */
.logo {
  width: 400px;  /* Adjust logo size as needed */
  height: auto;
}

/* Button styling */
.open-portal-button {
  width: 220px;
  height: 50px;
  font-size: 18px;
  border-radius: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB,qDAAqD;AACrD;EACE,YAAY;EACZ,SAAS;EACT,UAAU;EACV,yBAAyB;AAC3B;;AAEA,2CAA2C;AAC3C;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB,GAAG,wBAAwB;EAClD,mBAAmB,OAAO,sBAAsB;AAClD;;AAEA,2CAA2C;AAC3C;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB,GAAG,oCAAoC;EAC1D,SAAS,aAAa,kCAAkC;AAC1D;;AAEA,iBAAiB;AACjB;EACE,YAAY,GAAG,+BAA+B;EAC9C,YAAY;AACd;;AAEA,mBAAmB;AACnB;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB","sourcesContent":["/* src/App.css */\n\n/* Ensure the body and root take up the full height */\nhtml, body, #root {\n  height: 100%;\n  margin: 0;\n  padding: 0;\n  background-color: #f0f2f5;\n}\n\n/* Main container that centers everything */\n.app-container {\n  height: 100%;\n  display: flex;\n  justify-content: center;  /* Center horizontally */\n  align-items: center;      /* Center vertically */\n}\n\n/* Flex container for the logo and button */\n.logo-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;  /* Center the content horizontally */\n  gap: 20px;            /* Space between logo and button */\n}\n\n/* Logo styling */\n.logo {\n  width: 400px;  /* Adjust logo size as needed */\n  height: auto;\n}\n\n/* Button styling */\n.open-portal-button {\n  width: 220px;\n  height: 50px;\n  font-size: 18px;\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
