// src/App.js
import React from 'react';
import { Button } from 'antd';
import './App.css';

function App() {
  const handleOpenPortal = () => {
    window.location.href = 'https://portal.outageapp.com';
  };

  return (
    <div className="app-container">
      <div className="logo-container">
        <img src="/logo.png" alt="OutageApp Logo" className="logo" />
        <Button
          type="primary"
          size="large"
          onClick={handleOpenPortal}
          className="open-portal-button"
        >
          Open Portal
        </Button>
      </div>
    </div>
  );
}

export default App;
